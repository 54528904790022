@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

.tokens-table img {
  max-height: 100px;
}

.img-contain {
  object-fit: contain;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.pre-wrap {
  white-space: pre-wrap;
}
